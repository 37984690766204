<template>
  <div class="source-box">
    <div>
      <span
        class="source-tag flex-start"
        :class="source.source"
      >
        <span class="logo">
          <span
            v-if="source.source === 'facebook'"
            class="facebook"
          >
            <img :src="require('@/assets/images/source-icon/facebook.jpg')" />
          </span>
          <span
            v-else-if="source.source === 'twitter'"
            class="twitter"
          >
            <img
              :src="require('@/assets/images/source-icon/svg/twitter.svg')"
            />
          </span>
          <span
            v-else-if="source.source === 'instagram'"
            class="instagram"
          >
            <img
              :src="require('@/assets/images/source-icon/svg/instagram.svg')"
            />
          </span>
          <span
            v-else-if="source.source === 'youtube'"
            class="youtube"
          >
            <img
              :src="require('@/assets/images/source-icon/svg/youtube.svg')"
            />
          </span>
          <span
            v-else-if="source.source === 'pantip'"
            class="pantip"
          >
            <img :src="require('@/assets/images/source-icon/svg/pantip.svg')" />
          </span>
          <span
            v-else-if="source.source === 'tiktok'"
            class="tiktok"
          >
            <img :src="require('@/assets/images/source-icon/svg/tiktok.svg')" />
          </span>
          <span
            v-else-if="source.source === 'blockdit'"
            class="blockdit"
          >
            <img
              :src="require('@/assets/images/source-icon/svg/blockdit.svg')"
            />
          </span>
          <span
            v-else
            class="website"
          >
            <img
              :src="require('@/assets/images/source-icon/svg/website.svg')"
            />
          </span>
        </span>
      </span>
      <span class="ml-2 font">{{ source.name }}</span>
    </div>
    <div
      v-if="showId && source.id"
      class="ml-2 font text-muted"
    >
      (ID: {{ source.id }})
    </div>
  </div>
</template>

<script>
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
export default {
  props: {
    showId: {
      type: Boolean,
      default: false,
    },
    source: {
      type: Object,
      default: () => {
        return {
          source: 'facebook',
          name: 'KBANK Live',
        };
      },
    },
  },

  data() {
    return {
      faFacebookF,
      faYoutube,
      faTwitter,
      faInstagram,
    };
  },
};
</script>
<style lang="scss" scoped>
.font {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
}

.source-tag {
  // border-radius: 18px;
  height: 16px;
  line-height: 14px;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  // padding: 2px 5px 2px 2px;
  white-space: nowrap;
  display: inline-block;
  .logo {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    color: #fff;
    margin-right: 4px;
    line-height: 14px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // background-color: #252525;
  }
  &.facebook {
    img {
      width: 16px;
      height: 16px;
    }
  }
  &.twitter {
    .logo {
      background-color: rgb(34, 164, 240);
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.pantip {
    .logo {
      background-color: rgba(118, 116, 188, 1);
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.youtube {
    .logo {
      background-color: #ff0000;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.instagram {
    .logo {
      background-color: rgba(228, 64, 95, 1);
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.tiktok {
    .logo {
      background-color: #252525;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.website {
    .logo {
      background-color: #252525;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.blockdit {
    .logo {
      background-color: #4a69ff;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.pantip img,
.website img {
  width: 16px;
  height: 16px;
}
.mr-16 {
  margin-right: 16px;
}

.ant-select-selection-item div {
  align-items: center;
  margin-top: 7px;
  display: flex;
}

.source-box {
  display: flex;
  align-items: flex-start;
}

.text-muted {
  color: #b7b7b7;
}
</style>
