<template>
  <div class="text-center">
    <img
      class="img-logo"
      src="../../../assets/images/nodata/folder.svg"
      alt="no data"
    />
    <div>
      <h5 class="no-data-title">No Group of Social Media Accounts</h5>
      <p class="no-data-desc">
        Create a new group of social media accounts that you focus on
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoDataFound',
};
</script>

<style lang="scss" scoped>
.no-data-title {
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d92;
}

.no-data-desc {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d92;
}
</style>
